import React, { FC, useContext, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { SubscriptionsContext } from "contexts/subscriptions";
import { LocalizeText } from "components/localizer";
import TopBar from "components/tableComponents/topBar";
import Banner from "components/banner";
import { MembershipsContext } from "contexts/memberships";
import SearchField from "components/tableComponents/paginationSearchField";
import { SubscriptionsTable } from "components/tableComponents/subscriptionsTable";
import { TDropdownItem } from "components/dropdown";
import { usePaginationStore } from "utils/usePaginationStore";

const SubscriptionsView: FC = () => {
    const { isFetchingMembership, membership, fetchMembership } = useContext(MembershipsContext);
    const { getBariumRequestMoreMembershipURL } = useContext(SubscriptionsContext);
    const navigate = useNavigate();

    const { membershipId } = useParams<{ membershipId: string }>();

    const pagination = usePaginationStore("subscriptions", undefined, {
        active: true,
        membership_id: membershipId,
        page_size: 10,
    });

    useEffect(() => {
        if (!membership && !isFetchingMembership && membershipId) {
            fetchMembership(parseInt(membershipId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membershipId]);

    const menuItems = useMemo(() => {
        const items: TDropdownItem[] = [];
        if (membership) {
            items.push({
                onClick: () => navigate(`/staff-memberships/${membership.id}/details`),
                label: <LocalizeText tag="showMembershipAndCustomerDetails" />,
            });
        }
        items.push({
            onClick: () => getBariumRequestMoreMembershipURL(Number(membershipId)),
            canLoad: true,
            iconRight: <OpenInNewIcon />,
            label: <LocalizeText tag="requestNewSubscriptions" />,
        });
        return items;
    }, [membership, membershipId, getBariumRequestMoreMembershipURL, navigate]);

    return (
        <div className="contentWrapper">
            <Banner
                title="subscriptions"
                subTitle="bannerSubscriptions"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/subscriptions-1024x683.jpg`}
            />
            <TopBar
                searchField={<SearchField pagination={pagination} tag="searchSubscriptions" />}
                menuItems={menuItems}
            />
            <SubscriptionsTable pagination={pagination} loading={pagination.query.isFetching} />
            {pagination.results.length === 0 && !pagination.query.isFetching && (
                <LocalizeText tag="noSubscriptionLeftInfo" styling={{ padding: "10px" }} />
            )}
        </div>
    );
};
export default SubscriptionsView;
