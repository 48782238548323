import { TCommunity, TProductCommunity } from "utils/ecommerseTypes";
import PaginationStore, { TPaginationActionData } from "utils/paginationStore";
import { IInvitation } from "./leases";
import { IContactPerson, IOrganization } from "./memberships";
import { IRole } from "./roleManagement";

export interface ICommunityStaff extends IContactPerson {
    communities: TCommunity[];
    role: IRole;
}

export interface ICommunityStaffInvitation extends IInvitation {
    communities: TCommunity[];
}

export interface INewCommunityStaff {
    communities: string[];
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

export interface IEditCommunityStaff {
    communities: string[];
    role: number;
}

export interface ICostCalculationRun {
    id: number;
    uuid: string;
    created: string;
    offset: number;
    start_date: string;
    changed_last_invoice_dates: boolean;
    file: string;
}

export interface ICreateCostCalculationRun {
    offset: number;
    start_date: string;
    file_name: string;
}

export interface IRepresentative {
    uuid: string;
    id: number;
    full_name: string;
    email: string;
    memberships: IOrganization[];
}

export interface IRepresentativeInvitation {
    uuid: string;
    id: number;
    full_name: string;
    email: string;
    organization: IOrganization;
    created: string;
    updated: string;
    activates: string;
    mail_sent: boolean;
}

export interface ICommunityState {
    communityStaffPagination: PaginationStore<ICommunityStaff>;
    communityStaffInvitationPagination: PaginationStore<ICommunityStaffInvitation>;
    communitiesPagination: PaginationStore<TCommunity>;
    productCommunitiesPagination: PaginationStore<TProductCommunity>;
    costCalculationPagination: PaginationStore<ICostCalculationRun>;
    representativePagination: PaginationStore<IRepresentative>;
    representativeInvitationPagination: PaginationStore<IRepresentativeInvitation>;
    isFetchingCommunityStaff: boolean;
    isFetchingCommunityStaffInvitations: boolean;
    isFetchingCommunity: boolean;
    isFetchingCommunities: boolean;
    isFetchingProductCommunities: boolean;
    isFetchingCostCalculations: boolean;
    isAddingCommunityStaff: boolean;
    isEditingCommunityStaff: boolean;
    isDeactivatingCommunityStaff: boolean;
    isAddingCostCalculation: boolean;
    isFetchingRepresentatives: boolean;
    isFetchingRepresentativeInvitations: boolean;
    addCommunityStaff: (communityStaff: INewCommunityStaff) => Promise<boolean>;
    editCommunityStaff: (userId: number, data: IEditCommunityStaff) => Promise<boolean>;
    deactivateCommunityStaff: (userId: number) => Promise<void>;
    addCostCalculation: (data: ICreateCostCalculationRun) => Promise<Blob | null>;
    fetchCommunity: (uuid: string) => Promise<TCommunity>;
}

export const initialState: ICommunityState = {
    communityStaffPagination: new PaginationStore<ICommunityStaff>(),
    communityStaffInvitationPagination: new PaginationStore<ICommunityStaffInvitation>(),
    communitiesPagination: new PaginationStore<TCommunity>(),
    productCommunitiesPagination: new PaginationStore<TProductCommunity>(),
    costCalculationPagination: new PaginationStore<ICostCalculationRun>(),
    representativePagination: new PaginationStore<IRepresentative>(),
    representativeInvitationPagination: new PaginationStore<IRepresentativeInvitation>(),
    isFetchingCommunityStaff: false,
    isFetchingCommunityStaffInvitations: false,
    isFetchingCommunity: false,
    isFetchingCommunities: false,
    isFetchingProductCommunities: false,
    isFetchingCostCalculations: false,
    isAddingCommunityStaff: false,
    isEditingCommunityStaff: false,
    isDeactivatingCommunityStaff: false,
    isAddingCostCalculation: false,
    isFetchingRepresentatives: false,
    isFetchingRepresentativeInvitations: false,
    addCommunityStaff: async (communityStaff: INewCommunityStaff) => true,
    editCommunityStaff: async (userId: number, data: IEditCommunityStaff) => true,
    deactivateCommunityStaff: async (): Promise<void> => {
        return;
    },
    addCostCalculation: async (data: ICreateCostCalculationRun): Promise<Blob | null> => new Blob(),
    fetchCommunity: async (uuid: string) => ({}) as TCommunity,
};

export type IAction =
    | { type: "FETCH_COMMUNITY_STAFF" }
    | { type: "FETCH_COMMUNITY_STAFF_SUCCESS"; data: TPaginationActionData<ICommunityStaff> }
    | { type: "FETCH_COMMUNITY_STAFF_FAILURE" }
    | { type: "FETCH_COMMUNITY_STAFF_INVITATIONS" }
    | { type: "FETCH_COMMUNITY_STAFF_INVITATIONS_SUCCESS"; data: TPaginationActionData<ICommunityStaffInvitation> }
    | { type: "FETCH_COMMUNITY_STAFF_INVITATIONS_FAILURE" }
    | { type: "FETCH_COMMUNITY" }
    | { type: "FETCH_COMMUNITY_SUCCESS" }
    | { type: "FETCH_COMMUNITY_FAILURE" }
    | { type: "FETCH_PRODUCT_COMMUNITIES" }
    | { type: "FETCH_PRODUCT_COMMUNITIES_SUCCESS"; data: TPaginationActionData<TCommunity> }
    | { type: "FETCH_PRODUCT_COMMUNITIES_FAILURE" }
    | { type: "FETCH_COMMUNITIES" }
    | { type: "FETCH_COMMUNITIES_SUCCESS"; data: TPaginationActionData<TCommunity> }
    | { type: "FETCH_COMMUNITIES_FAILURE" }
    | { type: "FETCH_COST_CALCULATIONS" }
    | { type: "FETCH_COST_CALCULATIONS_SUCCESS"; data: TPaginationActionData<ICostCalculationRun> }
    | { type: "FETCH_COST_CALCULATIONS_FAILURE" }
    | { type: "ADD_COST_CALCULATION" }
    | { type: "ADD_COST_CALCULATION_SUCCESS" }
    | { type: "ADD_COST_CALCULATION_FAILURE" }
    | { type: "ADD_COMMUNITY_STAFF" }
    | { type: "ADD_COMMUNITY_STAFF_SUCCESS" }
    | { type: "ADD_COMMUNITY_STAFF_FAILURE" }
    | { type: "EDIT_COMMUNITY_STAFF" }
    | { type: "EDIT_COMMUNITY_STAFF_SUCCESS" }
    | { type: "EDIT_COMMUNITY_STAFF_FAILURE" }
    | { type: "DEACTIVATE_COMMUNITY_STAFF" }
    | { type: "DEACTIVATE_COMMUNITY_STAFF_SUCCESS" }
    | { type: "DEACTIVATE_COMMUNITY_STAFF_FAILURE" }
    | { type: "FETCH_REPRESENTATIVE" }
    | { type: "FETCH_REPRESENTATIVE_SUCCESS"; data: TPaginationActionData<IRepresentative> }
    | { type: "FETCH_REPRESENTATIVE_FAILURE" }
    | { type: "FETCH_REPRESENTATIVE_INVITATION" }
    | { type: "FETCH_REPRESENTATIVE_INVITATION_SUCCESS"; data: TPaginationActionData<IRepresentativeInvitation> }
    | { type: "FETCH_REPRESENTATIVE_INVITATION_FAILURE" };

const reducer = (state: ICommunityState, action: IAction): ICommunityState => {
    switch (action.type) {
        case "FETCH_COMMUNITY_STAFF":
            return { ...state, isFetchingCommunityStaff: true };
        case "FETCH_COMMUNITY_STAFF_SUCCESS":
        case "FETCH_COMMUNITY_STAFF_FAILURE":
            return { ...state, isFetchingCommunityStaff: false };

        case "FETCH_COMMUNITY_STAFF_INVITATIONS":
            return { ...state, isFetchingCommunityStaffInvitations: true };
        case "FETCH_COMMUNITY_STAFF_INVITATIONS_SUCCESS":
        case "FETCH_COMMUNITY_STAFF_INVITATIONS_FAILURE":
            return { ...state, isFetchingCommunityStaffInvitations: false };

        case "FETCH_COMMUNITY":
            return { ...state, isFetchingCommunity: true };
        case "FETCH_COMMUNITY_SUCCESS":
        case "FETCH_COMMUNITY_FAILURE":
            return { ...state, isFetchingCommunity: false };

        case "FETCH_PRODUCT_COMMUNITIES":
            return { ...state, isFetchingProductCommunities: true };
        case "FETCH_PRODUCT_COMMUNITIES_SUCCESS":
        case "FETCH_PRODUCT_COMMUNITIES_FAILURE":
            return { ...state, isFetchingProductCommunities: false };

        case "FETCH_COMMUNITIES":
            return { ...state, isFetchingCommunities: true };
        case "FETCH_COMMUNITIES_SUCCESS":
        case "FETCH_COMMUNITIES_FAILURE":
            return { ...state, isFetchingCommunities: false };

        case "FETCH_COST_CALCULATIONS":
            return { ...state, isFetchingCostCalculations: true };
        case "FETCH_COST_CALCULATIONS_SUCCESS":
        case "FETCH_COST_CALCULATIONS_FAILURE":
            return { ...state, isFetchingCostCalculations: false };

        case "ADD_COMMUNITY_STAFF":
            return {
                ...state,
                isAddingCommunityStaff: true,
                isFetchingCommunityStaff: true,
                isFetchingCommunityStaffInvitations: true,
            };
        case "ADD_COMMUNITY_STAFF_SUCCESS":
            const cStaffPagination = state.communityStaffPagination;
            const cStaffInvitationPagination = state.communityStaffInvitationPagination;
            cStaffPagination.reloadPage();
            cStaffInvitationPagination.reloadPage();
            return {
                ...state,
                communityStaffPagination: cStaffPagination,
                communityStaffInvitationPagination: cStaffInvitationPagination,
                isAddingCommunityStaff: false,
                isFetchingCommunityStaff: false,
                isFetchingCommunityStaffInvitations: false,
            };
        case "ADD_COMMUNITY_STAFF_FAILURE":
            return {
                ...state,
                isAddingCommunityStaff: false,
                isFetchingCommunityStaff: false,
                isFetchingCommunityStaffInvitations: false,
            };

        case "EDIT_COMMUNITY_STAFF":
            return {
                ...state,
                isEditingCommunityStaff: true,
                isFetchingCommunityStaff: true,
            };
        case "EDIT_COMMUNITY_STAFF_SUCCESS":
            const communityStaffPag = state.communityStaffPagination;
            communityStaffPag.reloadPage();
            return {
                ...state,
                communityStaffPagination: communityStaffPag,
                isEditingCommunityStaff: false,
                isFetchingCommunityStaff: false,
            };
        case "EDIT_COMMUNITY_STAFF_FAILURE":
            return {
                ...state,
                isEditingCommunityStaff: false,
                isFetchingCommunityStaff: false,
            };

        case "DEACTIVATE_COMMUNITY_STAFF":
            return { ...state, isDeactivatingCommunityStaff: true };
        case "DEACTIVATE_COMMUNITY_STAFF_SUCCESS":
            return { ...state, isDeactivatingCommunityStaff: false };
        case "DEACTIVATE_COMMUNITY_STAFF_FAILURE":
            return { ...state, isDeactivatingCommunityStaff: false };

        case "ADD_COST_CALCULATION":
            return {
                ...state,
                isAddingCostCalculation: true,
                isFetchingCostCalculations: true,
            };
        case "ADD_COST_CALCULATION_SUCCESS":
            const cCalc = state.costCalculationPagination;
            cCalc.reloadPage();
            return {
                ...state,
                costCalculationPagination: cCalc,
                isAddingCostCalculation: false,
                isFetchingCostCalculations: false,
            };
        case "ADD_COST_CALCULATION_FAILURE":
            return {
                ...state,
                isAddingCostCalculation: false,
                isFetchingCostCalculations: false,
            };

        case "FETCH_REPRESENTATIVE":
            return { ...state, isFetchingRepresentatives: true };
        case "FETCH_REPRESENTATIVE_SUCCESS":
        case "FETCH_REPRESENTATIVE_FAILURE":
            return { ...state, isFetchingRepresentatives: false };

        case "FETCH_REPRESENTATIVE_INVITATION":
            return { ...state, isFetchingRepresentativeInvitations: true };
        case "FETCH_REPRESENTATIVE_INVITATION_SUCCESS":
        case "FETCH_REPRESENTATIVE_INVITATION_FAILURE":
            return { ...state, isFetchingRepresentativeInvitations: false };

        default:
            return { ...state };
    }
};

export default reducer;
