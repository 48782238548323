import React, { FC, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { format, isBefore, parseISO } from "date-fns";
import { dateFormats } from "utils/formats";
import VKButton from "./vkButton";
import { ModalContext } from "contexts/modals";
import { EditDiscountDrawer } from "./drawers/editDiscountDrawer";
import colors from "styles/colors";
import { IDiscount, TDiscountType } from "adapters/discountsAdapter";
import PrettifyPrice from "./prettifyPrice";
import { ISubscription } from "reducers/subscriptions";
import { LanguageContext, TLanguageTag } from "contexts/language";
import { LocalizeText } from "./localizer";
import { getMonth } from "utils/helpers";
import { usePermission } from "utils/hooks/usePermission";

interface DiscountItemProps {
    subscription: ISubscription;
    discount: IDiscount;
    max_amount_excl_vat: number;
    onUpdate: (updated: Partial<IDiscount>) => void;
    onDelete: () => void;
    type?: TDiscountType;
}

export const DiscountListItem: FC<DiscountItemProps> = ({
    subscription,
    discount,
    max_amount_excl_vat,
    onUpdate,
    onDelete,
    type,
}) => {
    const { openDrawer } = useContext(ModalContext);
    const { currentLanguage } = useContext(LanguageContext);
    const { hasPermission } = usePermission();
    const { description, discount_from, discount_until, amount_excl_vat, category, internal_note } = discount;

    const startDate = parseISO(discount_from ?? "");
    const nextInvoiceDate = parseISO(subscription.next_invoice_date ?? "");

    const isOldDiscount = isBefore(parseISO(discount_until ?? ""), nextInvoiceDate);

    const showEditButton =
        ((category !== "one-time" && hasPermission("change_subscriptiondiscount")) ||
            (category === "one-time" && hasPermission("delete_subscriptiondiscount"))) &&
        !isOldDiscount;

    const categoryTag: TLanguageTag = discount.category === "one-time" ? "oneTimeAmount" : "monthlyAmount";
    return (
        <Grid item container width="100%" justifyContent="space-between" flexWrap="nowrap">
            <Grid item color={isOldDiscount ? colors.dimmedText : undefined}>
                <Grid container direction="column">
                    <span>
                        <LocalizeText tag="description" />: {description}
                    </span>
                    <Grid item container gap="5px 20px">
                        <Grid item>
                            <LocalizeText tag={categoryTag} />
                        </Grid>
                        <Grid item>
                            {<PrettifyPrice amount={amount_excl_vat} />} (
                            {Math.round((parseFloat(amount_excl_vat) / max_amount_excl_vat) * 100)}%)
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                color={isOldDiscount ? `${colors.dimmedText} !important` : undefined}
                            >
                                {categoryTag === "oneTimeAmount" ? (
                                    <>
                                        {getMonth(startDate, currentLanguage)} {startDate.getFullYear()}
                                    </>
                                ) : (
                                    <>
                                        {format(startDate, dateFormats.WEBDATE)}
                                        <span style={{ margin: "0 10px" }}>{"->"}</span>
                                        {discount_until ? (
                                            format(parseISO(discount_until ?? ""), dateFormats.WEBDATE)
                                        ) : (
                                            <LocalizeText tag="untilFurtherNotice" />
                                        )}
                                    </>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                    <span>
                        <LocalizeText tag="internalNote" />: {internal_note}
                    </span>
                </Grid>
            </Grid>
            {showEditButton ? (
                <Grid item width="max-content">
                    <VKButton
                        template="primary"
                        tag="edit"
                        size="small"
                        onClick={() =>
                            openDrawer(
                                EditDiscountDrawer,
                                { subscription, discount, max_amount_excl_vat, onUpdate, onDelete, type },
                                "editDiscount"
                            )
                        }
                    />
                </Grid>
            ) : null}
        </Grid>
    );
};
